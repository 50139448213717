import Anthropic from "@anthropic-ai/sdk";
import OpenAI from "openai";

const models = {
    haiku: "claude-3-haiku-20240307",
    sonnet: "claude-3-sonnet-20240229",
    gpt4: "gpt-4o",
  };

const anthropic = new Anthropic({
    apiKey: process.env.ANTHROPIC_SECRET_KEY, // This is the default and can be omitted
    baseURL: "https://anthropic.hconeai.com/",
    maxRetries: 2,
    defaultHeaders: {
      "Helicone-Auth": "Bearer sk-q4k3xpy-u3jeixy-w3dsqsq-jryce2q ",
    },
  });

async function callAnthropic(
    model: string,
    system_prompt: string,
    user_prompt: string
  ) {
    const message = await anthropic.messages.create({
      max_tokens: 800,
      system: system_prompt,
      messages: [
        {
          role: "user",
          content: user_prompt,
        },
        {
          role: "assistant",
          content: "{",
        },
      ],
      model: model,
    });
  
    if (!message.content || message.content.length === 0) {
      throw new Error("No content received from Anthropics API.");
    }
  
    return "{" + message.content[0].text;
}

export async function callOpenAI(
  model: string,
  system_prompt: string,
  user_prompt: string,
  customerId?: string,
  issueId?: string
) {

  const isInternal = customerId === "39cd095b-4762-40ab-a129-fdeb6bff6953";

  const openai = new OpenAI({
    apiKey: "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",
    baseURL: "https://oai.hconeai.com/v1",
    defaultHeaders: {
      "Helicone-Auth": "Bearer sk-q4k3xpy-u3jeixy-w3dsqsq-jryce2q",
      "Helicone-Cache-Enabled": "true",
      ...(isInternal && {"Helicone-Lytix-Key": `sk-945ae32e0ef0c0ed8de6693c1e3902aa27f3f5a456fabc3a35`}),
      ...(customerId && { "Helicone-User-Id": customerId }),
      ...(issueId && { "Helicone-Property-IssueId": issueId }),
    },
  });

  console.log("Calling OpenAI with model: ", model);
  const chatCompletion = await openai.chat.completions.create({
    messages: [
      { role: "system", content: system_prompt },
      { role: "user", content: user_prompt },
    ],
    model: model,
  });

  if (!chatCompletion.choices || chatCompletion.choices.length === 0) {
    throw new Error("No content received from OpenAI API.");
  }

  return chatCompletion.choices[0].message.content;
}

export async function analyzeError(errorData: any, events: any, model: string = models.sonnet) {
    console.log("Analyzing Error with model: ", model);
    const bufferSizes = [10000, 7500, 5000, 2500]; // Different buffer sizes to try
    let filteredEvents, lastClickNode, result;
  
    for (let buffer of bufferSizes) {
      try {
        [filteredEvents, lastClickNode] = filterEvents(events, new Date(errorData.timestamp).getTime(), buffer);
        if (model === "gpt-4o") {
          result = await callOpenAI(model, generateSystemPrompt(errorData), generateUserPrompt(filteredEvents, errorData, lastClickNode));
        } else {
          result = await callAnthropic(model, generateSystemPrompt(errorData), generateUserPrompt(filteredEvents, errorData, lastClickNode));
        }
        return result; // Return the result if successful
      } catch (error: any) {
        if (error.message && error.message.includes("prompt is too long")) {
          console.log(`Reducing buffer size to ${buffer} due to prompt length error.`);
          continue; // Try the next buffer size
        } else {
          throw error; // If the error is not related to prompt length, throw it
        }
      }
    }
  
    throw new Error("All buffer sizes attempted, but the prompt is still too long.");
}
  
function generateSystemPrompt(errorData) {
    return `You are a website analyzer and debugger. Someone just used the website and they triggered an error in the code.
    Analyze this RRweb session replay JSON and determine what the user was doing. Pay attention to what's on the page, the users mouse, what the user clicks, and how the product behaves. 
    If you have it, page context is often very helpful (e.g. the user clicked the add to cart button on the wishlist page or the user viewed the login page)
    
    Do not make up clicks or actions that did not occur. It's possible the user was just viewing a page. And do not interpret text on the page as an action, only associate user actions with actions.
    
    The error we care about occurred at ${errorData.timestamp}. Don't pay much attention to the other errors in the stack. Do not mention specific IDs, elements, timestamps, or DOM info. Do not make anything up!

    Provide the following in a JSON format with no pre-amble before the JSON and don't say anything after the JSON as well:
    - Comprehensive list of what the user did and how the product behaved. E.g. Opened cart and clicked the "Purchase" button ("activity_list")
    - Comprehensive paragraph summary of what the user was doing and how the product behaved ("impact_summary_long")
    - Skimmable, two sentence summary of what happened. Make sure to note what user was doing and how product behaved. ("impact_summary_short")
    - A one to three word categorization of the issue ("issue_category") Don't use the word error or issue here.

    Here's an JSON format output for a different, example session:

    Example 1 (where click led to error):
    {
        "activity_list": [
        "User navigated to the shopping page",
        "User browsed through several product categories including sport and clothing",
        "User added one hat to their cart",
        "User added two shirts to their cart",
        "User attempted to open the cart",
        "Cart failed to load"
        ],å
        "impact_summary_long": "The user was actively shopping and added several items to their cart. Upon attempting to view their cart to possibly proceed to checkout, they encountered an issue where the cart did not load. This could potentially lead to a loss in sales as the user was unable to proceed with their purchase.",
        "impact_summary_short": "User attempted to open their cart after adding items, but the cart failed to load."
        "issue_category": "Cart Loading"
    }

    Example 2 (where error occured without click):
    {
        "activity_list": [
        "User navigated to the shopping page with many items on the page",
        "Progress bar started to move",
        ],
        "impact_summary_long": "The user went to the shopping page where many items were on the page. As the progress bar moved, they encountered an error.",
        "impact_summary_short": "User encountered an error on the shopping page as the progress bar moved."
        "issue_category": "Shopping"
    }
    
    Never report these examples word for word and do not include specific content from these examples. These are for structural guidance.`
    ;
}
  
function generateUserPrompt(filteredEvents, errorData, lastClickNode) {
    //Compress events, removing extra lines and tabs from the error data to
    const compressedFilteredEvents = JSON.stringify(filteredEvents, null, 2)
    .replace(/\n/g, '')
    .replace(/\t/g, '')
    .replace(/\s{2,}/g, ' ');

    // Include the last click node and the error data if they exist
    if (errorData && lastClickNode) {
        return `Here is the RRWeb Session ${compressedFilteredEvents} \n and the error that happened at ${new Date(errorData.timestamp).getTime()} you are focused on ${JSON.stringify(errorData.data)}. \n
        and the last click before the error was on ${JSON.stringify(lastClickNode)}`;
    } else if (errorData.timestamp && errorData.data) {
        return `Here is the RRWeb Session ${compressedFilteredEvents} \n and the error that happened at ${new Date(errorData.timestamp).getTime()} you are focused on ${JSON.stringify(errorData.data)}. Please note, there was no click in this interaction. \n`;
    } else {
        return `Here is the RRWeb Session ${compressedFilteredEvents}. Please note, there was no click in this interaction.`;
    }
}

// Get events before the definition of the clicked node and everything up to the error itself
function filterEvents(events: any[], timestamp: number, buffer: number): any[] {
    //Iterate over all the events and find the last event which were clicks
    let lastClickID = null;

    console.log("Events", JSON.stringify(events))
  
    for (let i = events.length - 1; i >= 0; i--) {

      // Event.type = 3 | IncrementalSnapshot
      // Data.source = 2 | MouseInteraction
      // Data.type = 1 | MouseDown
      // Data.type = 2 | Click
      // Data.type = 4 | Double Click
      if (events[i].type === 3 && (events[i].data.type === 2 || events[i].data.type === 4 || events[i].data.type === 1) && events[i].data.source === 2 && events[i].timestamp <= timestamp) {
        lastClickID = events[i].data.id;
        break;
      }
    }
  
    let [clickedNode, clickedNodeTimestamp] = getFirstEventWithID(events, lastClickID);

    // Check if the last click timestamp is within 10 seconds of the error timestamp
    if (clickedNodeTimestamp && Math.abs(timestamp - clickedNodeTimestamp) > 10000) {
      clickedNode = null;
      clickedNodeTimestamp = null;
    }

    const eventsNearClickedNodeAndNearError = events.filter((event) => {
      return (event.timestamp > clickedNodeTimestamp && Math.abs(event.timestamp - clickedNodeTimestamp) <= buffer) || 
             (event.timestamp < timestamp && (timestamp - event.timestamp) <= buffer * 2);
    });

    return [eventsNearClickedNodeAndNearError, clickedNode]
}
  
function getFirstEventWithID(events, idToFind) {

  function traverseNodes(node, parentTimestamp) {
      // Check if the node itself matches the ID we're looking for
      if (node.id === idToFind) {
          return [node, parentTimestamp];
      }

      // Traverse child nodes if they exist
      if (node.childNodes) {
          for (const child of node.childNodes) {
              const [resultNode, resultTimestamp] = traverseNodes(child, parentTimestamp);
              if (resultNode !== null) {
                  return [resultNode, resultTimestamp];
              }
          }
      }

      // Traverse adds if they exist
      if (node.adds) {
          for (const add of node.adds) {
              const [resultNode, resultTimestamp] = traverseNodes(add.node, parentTimestamp);
              if (resultNode !== null) {
                  return [resultNode, resultTimestamp];
              }
          }
      }

      // Traverse node attribute if it exists
      if (node.node) {
          const [resultNode, resultTimestamp] = traverseNodes(node.node, parentTimestamp);
          if (resultNode !== null) {
              return [resultNode, resultTimestamp];
          }
      }

      return [null, null];
  }

  for (const event of events) {
      if (event.data) {
          const [node, timestamp] = traverseNodes(event.data, event.timestamp);
          if (node !== null) {
              return [node, timestamp];
          }
      }
  }

  return [null, null];
}