import {
  getErrorTitleForDisplay,
  getEndpointForDisplayAndGrouping,
} from "../api/_utils/issueUtils";
import { callOpenAI } from "../api/_utils/sessionAnalysis";
import { ErrorSignature } from "@/components/ui/data/schema";

import { Tables } from "@/types/supabaseTypes";
type SignatureDatabaseRow = Tables<"error-signatures">;

export function getEndpointForGroup(endpoint: string): string {
  return endpoint.includes("<identifier>")
    ? endpoint.replace(/<identifier>/g, "{...}")
    : endpoint;
}

export function getErrorTitleForGroup(
  signature: string,
  codebaseId: string
): string {
  const errorTitle = signature.split(`${codebaseId}-`)[1];
  return errorTitle.includes("<identifier>")
    ? errorTitle.replace(/<identifier>/g, "{...}")
    : errorTitle;
}

export async function getSignatureAndAIEndpoint(
  issue: any
): Promise<{ signature: string; endpoint: string }> {
  const title = getErrorTitleForDisplay(issue);
  let endpoint = getEndpointForDisplayAndGrouping(issue);
  let issueSignature = `${issue.codebase_id}-${title}`;
  const aiEndpointAndTitle = await getEndpointAndTitleWithAI(
    endpoint,
    issue.codebase_id,
    title,
    issue.customer_id,
    issue.id
  );
  if (aiEndpointAndTitle) {
    issueSignature = `${issue.codebase_id}-${aiEndpointAndTitle.title}`;
    endpoint = aiEndpointAndTitle.endpoint;
  } else {
    console.error(
      `Error getting AI signature, returning old logic signature for error_id=${issue.id}`
    );
  }
  return { signature: issueSignature, endpoint };
}
// /home/llm/overview/1e1d22dc-cf17-40a5-b0a8-f5515382c4f6/settings/guardrails/-lytix-console-Unable to find model with id 1e1d22dc-cf17-40a5-b0a8-f5515382c4f6 and company 515795d1-c8ad-4972-a22b-fa6ecf9da58c
// /home/llm/overview/llama-3-8b-instruct/-lytix-console-Unable to find model with id llama-3-8b-instruct and company bc99ba4b-5bc1-46c3-9988-45002912f6c1
async function getEndpointAndTitleWithAI(
  endpoint: string,
  codebaseId: string,
  title: string,
  customerId?: string,
  issueId?: string
): Promise<{ endpoint: string; title: string } | null> {
  const systemPrompt = `You are an AI designed to standardize error messages by replacing unique identifiers such as UUIDs, hash values, and line numbers, with the placeholder '<identifier>'. Your task involves processing incoming JSON objects having 'endpoint' and 'title' keys, and producing the normalized JSON maintaining the same structure. Ensure your interventions do not alter critical error specifics such as versions of APIs, HTTP methods, generic error types/codes, or universal URL segments, which are essential for accurate error categorization. 

Observe how you can transform these examples:
Original input:
{ "endpoint":"api/v1/user", "title":"Error 500: Unable to process '/usr/jdoe/datafile_12345.json'" }
Normalized output:
{ "endpoint":"api/v1/user", "title":"Error 500: Unable to process '/usr/jdoe/<identifier>'" }

Another transformation:
Input JSON:
{ "endpoint":"workflows.create", "title":"UniqueViolation: duplicate key value violates unique constraint \"unique_workflow_name\"\nDETAIL: Key (workflow_name, workspace_id)=(shayan demo, 2621) already exists.\n" }
Output JSON:
{ "endpoint":"workflows.create", "title":"UniqueViolation: duplicate key value violates unique constraint <identifier>\n" }

Specifically address this example:
Input JSON:
{ "endpoint":"get_issues_of_last_day_route", "title":"SyntaxError: syntax error at or near \")\"\nLINE 7: ) SELECT\n ^\n" }
Output JSON:
{ "endpoint":"get_issues_of_last_day_route", "title":"SyntaxError: <identifier>" }

Your aim is to generate a more organized JSON object that retains essential error information, but without specific identifiers. Please note that while replacing identifiers, you should maintain the structure of the rest of the string, replacing only the unique identifiers.`;

  try {
    if (/^Loading chunk .* failed\./.test(title)) {
      title = "Loading chunk failed.";
    }
    if (endpoint.endsWith("/") && endpoint != "/") {
      endpoint = endpoint.slice(0, -1);
    }
    title = title.trim();
    const result = await callOpenAI(
      "gpt-4o-mini",
      systemPrompt,
      JSON.stringify({ endpoint, title }),
      customerId,
      issueId
    );
    if (!result) {
      return null;
    }
    console.log(
      `Signature computation: Endpoint:\n${endpoint}\n\nTitle:\n${title}\n\nResult:\n${result}`
    );
    // We expect this to return a JSON object with `endpoint` and `title` keys as in the prompt above.
    const parsedResult = JSON.parse(result);
    return parsedResult;
  } catch (error) {
    console.error("Error calling OpenAI for signature:", error);
    return null;
  }
}

/**
 * This function determines the appropriate issue ID to link to based on the given signature object.
 * It prioritizes the IDs on the signature in the below order.
 *
 * If none of these IDs are present, it defaults to the signature's own ID.
 *
 * Note: This function is used for clicks outside the label. If the user clicks on the label associated
 * with a given impact label, it will navigate to that specific error instead.
 *
 * @param {ErrorSignature} signatureObject - The signature object containing various error IDs.
 * @returns {string} - The appropriate issue ID to link to.
 */
export const getAppropriateIssueIdToLinkTo = (
  signatureObject: ErrorSignature | SignatureDatabaseRow
): string => {
  let usedField = '';
  let result = '';

  if (signatureObject.combined_impact_category) {
    switch (signatureObject.combined_impact_category.toLowerCase()) {
      case 'error message':
      case 'error appeared':
        result = signatureObject.most_recent_error_appeared?.toString() || '';
        usedField = 'most_recent_error_appeared';
        break;
      case 'did not load':
        result = signatureObject.most_recent_did_not_load?.toString() || '';
        usedField = 'most_recent_did_not_load';
        break;
      case 'crashed':
        result = signatureObject.most_recent_crashed?.toString() || '';
        usedField = 'most_recent_crashed';
        break;
      case 'blank page':
        result = signatureObject.most_recent_blank_page?.toString() || '';
        usedField = 'most_recent_blank_page';
        break;
      case 'unexpected behavior':
        result = signatureObject.most_recent_unexpected_behavior?.toString() || '';
        usedField = 'most_recent_unexpected_behavior';
        break;
      case 'works as intended':
        result = signatureObject.most_recent_works_as_intended?.toString() || '';
        usedField = 'most_recent_works_as_intended';
        break;
      case 'unknown':
        result = signatureObject.most_recent_unknown?.toString() || '';
        usedField = 'most_recent_unknown';
        break;
    }
  }

  // If combined_impact_category doesn't exist or doesn't match any case, fall back to the original logic
  if (!result) {
    if (signatureObject.most_recent_error_appeared) {
      result = signatureObject.most_recent_error_appeared.toString();
      usedField = 'most_recent_error_appeared';
    } else if (signatureObject.most_recent_did_not_load) {
      result = signatureObject.most_recent_did_not_load.toString();
      usedField = 'most_recent_did_not_load';
    } else if (signatureObject.most_recent_crashed) {
      result = signatureObject.most_recent_crashed.toString();
      usedField = 'most_recent_crashed';
    } else if (signatureObject.most_recent_blank_page) {
      result = signatureObject.most_recent_blank_page.toString();
      usedField = 'most_recent_blank_page';
    } else if (signatureObject.most_recent_unknown) {
      result = signatureObject.most_recent_unknown.toString();
      usedField = 'most_recent_unknown';
    } else if (signatureObject.most_recent_works_as_intended) {
      result = signatureObject.most_recent_works_as_intended.toString();
      usedField = 'most_recent_works_as_intended';
    } else if (signatureObject.most_recent_unexpected_behavior) {
      result = signatureObject.most_recent_unexpected_behavior.toString();
      usedField = 'most_recent_unexpected_behavior';
    } else {
      result = signatureObject.most_recent_error_id || "";
      usedField = 'most_recent_error_id';
    }
  }

  console.log(`Used field for issue sigid ${signatureObject.id}: ${usedField}`);
  return result;
};