import { Button } from "@/components/ui/button";
import { useSearchParams } from "next/navigation";

export default function FloatingMenu({ selectedCount, selectedIds, onDeselectAll, onRefresh }) {
  const searchParams = useSearchParams();
  const statusFilter = searchParams.get("status") || "active";

  if (selectedCount === 0) return null;

  const handleStatusChange = async () => {
    const newStatus = statusFilter === "resolved" ? "active" : "resolved";
    await fetch("/api/update_error_signatures_status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ids: selectedIds, status: newStatus }),
    });
    await onRefresh(); // Refresh the table data
    onDeselectAll(); // Deselect all rows
  };

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white bg-opacity-75 backdrop-blur-md rounded-lg shadow-lg p-4 z-50 flex items-center">
      <div className="flex items-center justify-between text-sm font-medium text-gray-900 mr-4">
        <span className="mr-4 ml-2">{selectedCount} issues selected</span> {/* Added margin-right */}
        <Button className="text-gray-500" variant="ghost" onClick={onDeselectAll}>
          ✕
        </Button>
      </div>
      <div className="flex space-x-2">
        <Button className="flex items-center" variant="outline" onClick={handleStatusChange}>
          {statusFilter === "resolved" ? "Mark Active" : "Mark Resolved"}
        </Button>
      </div>
    </div>
  );
}