"use client";

import { useEffect, useState } from "react";
// Dedicated component to not cause client/server mismatch hydration issues because of now.getTime()
export const TimeAgo = ({ timestamp, prefix = "", className ="" }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const updateTimeAgo = () => {
      const now = new Date();
      const diffInMilliseconds = now.getTime() - new Date(timestamp).getTime();
      const diffInMinutes = diffInMilliseconds / (1000 * 60);
      let timeAgoText;
      if (diffInMinutes < 1) {
        timeAgoText = "just now";
      } else if (diffInMinutes < 60) {
        timeAgoText = `${Math.floor(diffInMinutes)} minute${
          Math.floor(diffInMinutes) > 1 ? "s" : ""
        } ago`;
      } else if (diffInMinutes < 1440) {
        const diffInHours = Math.floor(diffInMinutes / 60);
        timeAgoText = `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
      } else {
        const diffInDays = Math.floor(diffInMinutes / 1440);
        timeAgoText = `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
      }
      setTimeAgo(timeAgoText);
    };

    updateTimeAgo();
    const intervalId = setInterval(updateTimeAgo, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [timestamp]);

  return (
    <span className={`text-sm text-gray-500 dark:text-gray-300 ${className}`}>
      {prefix} {timeAgo}
    </span>
  );
};
