import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const labelVariants = cva(
  "relative inline-block text-xs text-left font-normal leading-tight rounded lg:max-w-[500px] md:max-w-[350px]",
  {
    variants: {
      color: {
        blue: "rounded bg-sky-600 dark:bg-sky-800 p-[1px]",
        red: "rounded bg-rose-600 dark:bg-rose-800 p-[1px]",
        green: "rounded bg-green-600 dark:bg-green-800 p-[1px]",
        orange: "rounded bg-orange-600 dark:bg-orange-800 p-[1px]",
        purple: "rounded bg-violet-600 dark:bg-violet-800 p-[1px]",
        yellow: "rounded bg-yellow-600 dark:bg-yellow-800 p-[1px]",
        teal: "rounded bg-teal-600 dark:bg-teal-800 p-[1px]",
        gray: "rounded bg-gray-600 dark:bg-gray-800 p-[1px] hover:brightness-110",
        redToTealGradient:
          "rounded bg-gradient-to-r from-rose-500 to-teal-600 dark:from-rose-700 dark:to-teal-700 p-[1px] ",
        tealToRedGradient:
          "rounded bg-gradient-to-r from-teal-500 to-rose-600 dark:from-teal-700 dark:to-rose-700 p-[1px] ",
        purpleToPinkGradient:
          "rounded bg-gradient-to-r from-purple-500 to-pink-600 dark:from-purple-700 dark:to-pink-700 p-[1px]",
        blueToGreenGradient:
          "rounded bg-gradient-to-r from-blue-500 to-green-600 dark:from-blue-700 dark:to-green-700 p-[1px]",
        redToOrangeGradient:
          "rounded bg-gradient-to-r from-rose-500 to-orange-600 dark:from-rose-700 dark:to-orange-700 p-[1px]",
        greenToYellowGradient:
          "rounded bg-gradient-to-r from-green-500 to-yellow-600 dark:from-green-700 dark:to-yellow-700 p-[1px]",
        pinkToRedGradient:
          "rounded bg-gradient-to-r from-pink-500 to-red-600 dark:from-pink-700 dark:to-red-700 p-[1px]",
        cyanToBlueGradient:
          "rounded bg-gradient-to-r from-cyan-500 to-blue-600 dark:from-cyan-700 dark:to-blue-700 p-[1px]",
        redToBlueGradient:
          "rounded bg-gradient-to-r from-red-500 to-blue-600 dark:from-red-700 dark:to-blue-700 p-[1px]",
        redToGreenGradient:
          "rounded bg-gradient-to-r from-red-500 to-green-600 dark:from-red-700 dark:to-green-700 p-[1px]",
        redToYellowGradient:
          "rounded bg-gradient-to-r from-red-500 to-yellow-600 dark:from-red-700 dark:to-yellow-700 p-[1px]",
        redToPurpleGradient:
          "rounded bg-gradient-to-r from-red-500 to-purple-600 dark:from-red-700 dark:to-purple-700 p-[1px]",
        redToCyanGradient:
          "rounded bg-gradient-to-r from-red-500 to-cyan-600 dark:from-red-700 dark:to-cyan-700 p-[1px]",
        tealToYellowGradient:
          "rounded bg-gradient-to-r from-teal-500 to-yellow-600 dark:from-teal-700 dark:to-yellow-700 p-[1px]",
        default: "",
      }
    },
    defaultVariants: {
      color: "default",
    },
  }
);

interface LabelProps extends VariantProps<typeof labelVariants> {
  children: React.ReactNode;
  thickness?: 'thick' | 'thin';
  filledIn?: boolean
}

const Label: React.FC<LabelProps> = ({ children, color, thickness = "thick", filledIn = 'false' }) => {
  // Use labelVariants to only handle color, not thickness
  const classes = labelVariants({ color });
  // Apply thickness directly to the inner div where padding is needed
  const paddingClass = thickness === 'thin' ? 'p-1' : 'p-2';
  const marginClass = thickness === 'thin' ? '' : 'mr-1';

  return (
    <div className={cn(`relative inline-block ${marginClass}`)}>
      <div className={cn(classes)}>
        {/* Apply paddingClass here instead of through labelVariants */}
        <div className={cn(`relative rounded ${filledIn === true ? '' : 'bg-content'} ${paddingClass}`)}>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Label;