"use client"
import React from "react"
import {
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { MagicWandIcon } from "@radix-ui/react-icons"
import { LuInfo as InfoIcon } from "react-icons/lu"
import Label from "@/components/ui/label"
import { ImpactCategory } from "@/app/issue/types"

const getCategoryExplanation = (category: string): string => {
  switch (category) {
    case "unknown":
      return "Session had an impact that was unclear."
    case "works-as-intended":
      return "Session had the feature or functionality working correctly (as designed)."
    case "crashed":
      return "Session had the application or system unexpectedly stopping working or closing."
    case "did-not-load":
      return "Session had the expected content or feature failing to appear or become accessible."
    case "unexpected-page-behavior":
      return "Session had the application or system exhibiting unexpected behavior or not functioning as intended."
    case "error-message":
      return "Session had an error message appear on the page."
    case "blank-page":
      return "Session had a blank page appear."
    case "unexpected-behavior":
      return "Session had the application or system exhibiting unexpected behavior or not functioning as intended."
    default:
      return "Session had no specific explanation available for this category."
  }
}

const ImpactLabel = ({
  category,
  count,
  className = "",
  disableClick = true,
}: {
  category: ImpactCategory
  count?: number
  className?: string
  disableClick?: boolean
}) => {
  const stopProp = (e) => {
    e.stopPropagation()
  }
  return (
    <div
      className={`flex space-x-2 ${className}`}
      onClick={disableClick ? stopProp : undefined}>
      <TooltipProvider delayDuration={20}>
        <Tooltip>
          <TooltipTrigger>
            <Label
              color={
                category === "works-as-intended"
                  ? "gray"
                  : category === "crashed"
                    ? "red"
                    : category === "did-not-load"
                      ? "yellow"
                      : category === "unexpected-behavior"
                        ? "redToPurpleGradient"
                        : category === "unexpected-page-behavior"
                          ? "redToPurpleGradient"
                          : category === "unknown"
                            ? "gray"
                            : category === "error-message"
                              ? "red"
                              : category === "blank-page"
                                ? "redToYellowGradient"
                                : category === "drop-off"
                                  ? "blue"
                                  : "gray"
              }
              thickness="thin"
              filledIn={true}>
              <span className="flex items-center text-white">
                <MagicWandIcon className="mr-2" />
                {category
                  .replace(/-/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
                {count && <span className="ml-2">({count})</span>}
              </span>
            </Label>
          </TooltipTrigger>
          <TooltipContent
            side="right"
            align="start"
            className="max-w-screen-sm">
            <div className="flex items-center">
              <InfoIcon size={16} className="mr-2" />
              {getCategoryExplanation(category)}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}

export default ImpactLabel
